<template>
  <div class='dialogBox'>
    <el-dialog
      :visible='visible'
      v-bind="$attrs"
      destroy-on-close
      :modal='false'
      :modal-append-to-body='false'
      top='35%'
      width='80%'
      title='选择扣除方式'
      :before-close="handleCancel">
      <el-form
        ref="choosePackageForm"
        :model="choosePackageForm"
        :rules="choosePackageRules"
        size="small">
        <div class="tips-container">请选择您下载素材使用的套餐扣除方式</div>
        <el-form-item label="可选择" prop="packageId">
          <el-radio-group v-model="choosePackageForm.packageId">
            <el-radio
              v-for="(i, j) in packages"
              :name="i.openrecord_id"
              :key="`package-${j}`"
              :value="i.openrecord_id"
              :label="i.openrecord_id">
              {{ i.goods_type == 1 ? '个人' + i.goods_name : '企业' + i.goods_name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tips-container">
          <el-icon class="icon" name="warning"/>
          <span class='tips'>请在作品投放及会员到期前激活授权书，没有激活授权书的素材不可使用。</span>
        </div>
      </el-form>
      <div slot="footer">
        <el-button type="dark" @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleDownload">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCartList } from '@/utils/operate'
import { fetchUserVips } from '@/api/user'
import { getToken } from '@/utils/author'
import { operationInfoByTypeAndId } from "@/api/common";
export default {
  props: {
    visible:{
      type: Boolean,
      default: false
    },
    packageObj:{
      type: Object,
      default: null
    }
  },
  data(){
    return{
      choosePackageForm: {
        packageId: ''
      },
      packages: [],
      choosePackageRules: {
        packageId: [
          { required: true, message: '请选择套餐扣除方式', trigger: 'change' }
        ]
      },
    }
  },
  computed: {
    downloadList() {
      return [this.packageObj]
    }
  },
  watch: {
    visible:{
      handler(v){
        v && this.getVipsList();
      },
      immediate: true
    }
  },
  methods: {
    // 获取会员列表
    getVipsList() {
      fetchUserVips().then((res) => {
        const { data } = res
        this.packages = data;
      })
    },
    // 关闭弹框
    handleCancel() {
      this.$parent.visibleDialog = false;
    },
    handleDownload(){
      this.$refs.choosePackageForm.validate(async (valid) => {
        if (!valid) return;
        const { packageId } = this.choosePackageForm
        const { packages, downloadList } = this
        const [packageInfo] = packages.filter((i) => i.openrecord_id === packageId);
        const { down_count, down_num } = packageInfo

        // 如果日下载次数不为 n 并且日下载次数大于0
        if (down_num !== 'n') {
          // 打开下载提示框并且配置参数内容
          if (Number(down_num) <= 0) {
            this.$message({
              message: '当日下载次数使用完：今日下载数量已达上限，可明天继续下载或联系客服',
              type: 'warning'
            });
            return
          }
          if (Number(down_num) < downloadList.length) {
            this.$message({
              message: `今日下载数量剩余${down_num}次，请重新勾选，也可明天继续下载或联系客服`,
              type: 'warning'
            });
            return
          }
        }
        // 总下载次数限制
        if (down_count !== 'n') {
          if (Number(down_count) <= 0) {
            this.$message({
              message: `会员下载次数使用完：您套餐内下载次数已消耗完毕，可重新购买套餐或联系客服`,
              type: 'warning'
            });
            return
          }
          if (Number(down_count) < downloadList.length) {
            this.$message({
              message: `会员下载次数剩余${down_count}次，请重新勾选，可重新购买套餐或联系客服`,
              type: 'warning'
            });
            return
          }
        }

        // const info = {dayCount: down_num,packageCount: down_count};
        // 记录用户行为
        await operationInfoByTypeAndId({
          object_id: this.packageObj.object_id,
          mode: this.packageObj.type,
          type: 4
        });
        let formdata = new FormData();
        formdata.append("object_id",this.packageObj.object_id);
        formdata.append("openrecord_id",packageId);
        formdata.append("object_type",this.packageObj.type);
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/fontend/certificate/download`,{
          method: 'POST',
          headers: {
            'token': getToken()
          },
          body: formdata
        });
        let result = await response.json();
        if(result.code !== 0){
          this.$message.error(result.msg);
          return;
        }
        let a = document.createElement('a');
        a.href = result.data.down_url;
        a.style.display = 'none';
        a.download = "download"; // decodeURIComponent(filename)
        a.click();
        window.URL.revokeObjectURL(result.data.down_url);
        // 关闭
        this.handleCancel();
        await getCartList();
      })
    }
  }
}
</script>
<style scoped lang='scss'>
.dialogBox{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 5000;
  overflow: hidden;
  .tips-container{
    width: 100%;
    display: flex;
    align-items: center;
    .icon{
      font-size: 16px;
      color: #ff8e14;
    }
    .tips{
      display: inline-block;
      margin-left: 10px;
    }
  }
}
/deep/{
  .el-dialog__body{
    padding: 0 20px 20px 20px;
  }
  .el-form-item{
    width: 100%;
    display: flex;
  }
  .el-form-item__label{
    font-size: 14px;
  }
  .el-radio-group{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .el-radio{
    margin-top: 10px;
  }
  .el-radio__label{
    font-size: 14px;
  }
  .el-radio__inner{
    width: 16px;
    height: 16px;
  }
  .el-radio__original{
    display: none;
  }
  .el-button.el-button--dark{
    padding: 12px 20px;
    font-size: 14px;
  }
}
</style>
