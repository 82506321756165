<template>
  <div class="musicList">
    <SearchNav @search="search" :showTag='false'></SearchNav>
    <div class='detail'>
      <video-player
        v-if='!visibleDialog'
        ref="VueVideoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @play="handlePLay"
        :events="events"
        @fullscreenchange="handlefullscreenchange" />
      <div class='videobox' v-else>
        <img :src="videoInfo && videoInfo.watermark_file+'?vframe/jpg/offset/1'" />
      </div>
    </div>
    <div class='detail_info'>
      <h4 class='video_name'>{{(videoInfo && videoInfo.name) || ''}}</h4>
      <div class='scurce'>
        <span class='time'>时长：{{(videoInfo && formatSeconds(videoInfo.duration)) || ''}}</span>
        <div class='music_opera'>
          <img :src="require(`@/assets/svg/${videoInfo && videoInfo.is_collect? 'collected' : 'collect'}.svg`)" @click="handleMusicCollect(videoInfo)" />
          <!--<img :src="require('@/assets/svg/share-gray.svg')" @click="handleShare('', videoInfo.object_id, 4, videoInfo.name)" />-->
          <!--<img :src="require('@/assets/svg/download-gray.svg')" @click="handleDownload(videoInfo, 'single')" />-->
          <img :src="require('@/assets/svg/download-gray.svg')" @click="downloadFile(videoInfo)" />
        </div>
      </div>
      <div class='video_tag'>
        <div class='tagname'>标签：</div>
        <div class='tag_list'>
          <span class='name' v-for='(item,idx) in labels' :key='idx'>{{item}}</span>
        </div>
        <div class='tagname keywords'>关键词：</div>
        <div class='keywords_list'>
          <span class='name' v-for='(item,idx) in (videoInfo && videoInfo.keywords) || []' :key='idx'>{{item}}</span>
        </div>
      </div>
    </div>
    <dialogBox :packageObj='packageObj' :visible='visibleDialog' v-if='visibleDialog'></dialogBox>
  </div>
</template>
<script>
import dialogBox from "./components/DialigBox"
import Vue from 'vue'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import SearchNav from "./components/SearchNav.vue";
import Buttons from '@/layout/mixins/Buttons'
import Common from './mixins/Common';
import {fetchMusicInfo} from '@/api/music';
// import { EventBus } from '@/utils/bus'
Vue.use(VueVideoPlayer);
export default{
  components:{
    SearchNav,
    dialogBox
  },
  mixins: [Buttons,Common],
  data(){
    return{
      videoCanvas: null,
      isAndroid: false,
      isPlay: false,
      events: ['fullscreenchange'],
      videoInfo: null,
      playerOptions: {
        playbackRates: [0.5, 0.7, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: '',
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  computed: {
    labels() {
      if (this.videoInfo && this.videoInfo.label) {
        return this.videoInfo.label.map(item=>{
          return item.name;
        })
      }
      return [];
    },
  },
  created() {
    const ua = navigator.userAgent;
    this.isAndroid = ua.match(/(Android)/i) ? true : false;
  },
  mounted() {
    this.getDetail();
  },
  methods:{
    handlefullscreenchange(){
      this.handlePLay();
      // this.$refs.VueVideoPlayer.play();
    },
    //获取视频详情
    async getDetail(){
      const {data, code ,msg} = await fetchMusicInfo({
        music_id: this.$route.query.videoId
      });
      if(code !== 0){
        this.$message.error(msg);
        return;
      }
      this.videoInfo = data;
      this.playerOptions.sources = [
        data.watermark_file
      ]
      this.playerOptions.poster =  data.logo;
    },
    search(val){
      console.log(val)
      // this.keyword_name = val;
    },
    handlePLay() {
      this.$store.dispatch('music/pause')
    },
    // 秒转成时分秒
    formatSeconds(value) {
      if(value){
        let theTime = parseInt(value);// 秒
        let theTime1 = 0;// 分
        let theTime2 = 0;// 小时
        if (theTime > 60) {
          theTime1 = parseInt(theTime / 60);
          theTime = parseInt(theTime % 60);
          if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
          }
        }
        let result = parseInt(theTime);
        if(theTime2 > 0){
          // 小时
          result = parseInt(theTime2) + ":" + result;
        }
        if (theTime1 > 0) {
          // 分
          result = parseInt(theTime1) + ":" + result;
        }
        if(result > 60){
          let res = result.search(':') > -1 && result.split(":").map(item=>{
            item = item < 10 ? `0${item}` : item;
            return item;
          });
          return res.join(':');
        }else{
          return "00:"+result;
        }
      }else {
        return "";
      }
    },
    // 收藏
    async handleMusicCollect(item) {
      item.is_collect = item.is_collect === 0 ? 1 : 0;
      await this.handleCollect(item.object_id, 4, {
        ...item,
        type: 4,
        labels: item.label
      }, false)
    },
    // downloadItem(item){
    //   fetch(decodeURIComponent(item.down_url)).then(res=>{
    //     let a = document.createElement('a');
    //     a.href = res.url;
    //     a.download = "download";
    //     a.click();
    //     window.URL.revokeObjectURL(res.url);
    //   });
    // },

  }
}
</script>
<style lang="scss" scoped>
.musicList{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10 !important;
  .detail{
    width: 100%;
    height: 210px;
    position: relative;
    .video-player{
      width: 100%;
      height: 100%;
    }
    .videobox{
      width: 100%;
      height: 100%;
      background: #000;
      img{
        width: 100%;
        height: 100%;
      }
    }
    //#video{
    //  visibility: hidden;
    //  //display: none;
    //  width: 100%;
    //  height: 100%;
    //}
  }
  .detail_info{
    width: 100%;
    padding: 20px 17px 20px 25px;
    box-sizing: border-box;
    overflow: hidden;
    .video_name{
      width: 100%;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      padding: 0;
      margin: 0;
    }
    .scurce{
      margin-top: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .time{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51,51,51,0.5);
      }
      .music_opera{
        margin-left: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        img{
          display: inline-block;
          margin: 0 8px;
          &:first-child,&:last-child{
            height: 20px;
          }
          &:nth-child(2){
            height: 20px;
          }
        }
      }
    }
    .video_tag{
      width: 100%;
      margin-top: 16px;
      border-top: 1px solid #F8F8F9;
      .tagname{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        margin-top: 12px;
      }
      .keywords{
        margin-top: 20px;
      }
      .tag_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .name{
          display: inline-block;
          background: rgba(255,47,15,0.05);
          border-radius: 11px;
          padding: 3px 8px;
          box-sizing: border-box;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255,47,15,0.75);
          margin-right: 10px;
          margin-top: 12px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .keywords_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .name{
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51,51,51,0.75);
          line-height: 16px;
          margin-right: 20px;
          margin-top: 12px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}
.canvas{
  width: 100%;
  height: 100%;
}

/deep/{
  .xgplayer{
    z-index: 10 !important;
  }

  .vjs-fluid{
    height: 100%;
    padding-top: 210px !important;
    z-index: 1 !important;
  }
  .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video-js,.video-player{
    z-index: 1 !important;
    width: 100%;
    height: 100%;
  }
  .video-js .vjs-progress-holder{
    height: 2px !important;
    margin: 0 !important;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .video-js .vjs-progress-control:hover .vjs-progress-holder{
    font-size: 14px !important;
  }
  .video-js .vjs-play-progress:before {
    font-size: 10px !important;
  }
}
</style>
