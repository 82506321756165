import { mapGetters } from 'vuex'
import { getToken } from "@/utils/author";
import { send_eventApi } from '@/utils/common.js'
import { formDateTime } from '@/utils/formDate.js'

export default {
  inject: ['reload'],
  computed: {
    ...mapGetters(['hasMember','userInfo']),
  },
  data(){
    return{
      visibleDialog: false,
      packageObj: null
    }
  },
  methods: {
    // 下载
    async downloadFile(info) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (!info) {
        throw new Error('下载的内容不是一个有效的对象或者数组')
      }
      if (!this.hasMember) {
        this.$router.push('/package')
        return
      }
      this.packageObj = {...info};
      this.visibleDialog = true;

      let obj = {};
      const types = {
        '1': 'AI语音',
        '2': '音乐',
        '3': '音效',
        '4': '视频'
      }
      if(Array.isArray(info)){
        const infos = info.map(i => ({
          m_type: types[i.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: i.name,
          mid: i.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }))
        infos.forEach(i => {
          send_eventApi('e_download', i)
        })
      }else{
        obj = {
          m_type: types[info.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: info.name,
          mid: info.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }
        send_eventApi('e_download', obj)
      }
    }
  }
}
